import { Moment } from 'moment';
import { QueryControl, QueryControlType } from '@/model/query-control';
import { post } from '@/service/request';
import ReportService, { REPORT_URL_PATH, assignDefaultExtend, timeTransformData } from '@/service/report';
import { QueryModel } from '@/model/query-model';

export class DhcHourCopQueryModel extends QueryModel {
   @QueryControl({
       label: '日期范围',
       type: QueryControlType.SELECT_DATE,
       showTime: false,
       span: 10,
       require: true
   })
    dateRange: Array<Moment> = JTL.CONSTANT.TODAY();

   toService() {
       return {
           startTime: this.dateRange[0].startOf('day').toDate().getTime(),
           endTime: this.dateRange[1].clone().add(1, 'day').startOf('day').toDate().getTime()
       };
   }
}

export async function DhcHourCopService(model: DhcHourCopQueryModel) {
    const url = `${REPORT_URL_PATH}/equipment/dhc/cop/certainDate`;
    const params = model.toService();
    const rows:any = await post(url, params);
    // const rows: any = await getReportCOPJsonData();

    const chartOptions = {
        data: {
            columns: ['timestamp', 'todayCapacity', 'yesterdayCapacity', 'ratio'],
            rows: rows.data
        },
        settings: {
            type: 'line',
            showLine: ['todayCapacity', 'yesterdayCapacity', 'ratio'],
            labelMap: {
                todayCapacity: '当天',
                yesterdayCapacity: '昨天',
                ratio: '环比'
            },
            axisSite: { right: ['ratio'] }
        },
        extend: {
            yAxis: [
                {
                    name: '单位：台',
                    position: 'left',
                    axisLine: {
                        show: true
                    },
                    splitLine: {
                        lineStyle: {
                            type: 'dashed'
                        }
                    }
                },
                {
                    name: '单位：台',
                    position: 'right',
                    axisLine: {
                        show: true
                    },
                    splitLine: {
                        show: false
                    }
                }
            ]

        }
    };
    return assignDefaultExtend(chartOptions);
}
