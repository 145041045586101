import moment from 'moment';
import { GraphGroupName } from '@/model/enum';
import { QueryControl, QueryControlType } from '@/model/query-control';
import { post } from '@/service/request';
import ReportService, { REPORT_URL_PATH, assignDefaultExtend, timeTransformData } from '@/service/report';
import { QueryModel } from '@/model/query-model';
import { ParkingTypeList } from './parking-area-hour-trend';

export class ParkingAreaRealtimeQueryModel extends QueryModel {
    @QueryControl({
        label: '区域选择',
        type: QueryControlType.SELECT,
        mode: 'multiple',
        optionsPromise: ReportService.getLocationList,
        optionsPromiseParam: GraphGroupName.CAR_PACKING_AREA,
        index: 10,
        span: 8,
        defaultValue: 'ALL',
        required: true
    })
    areaId: Array<string> = undefined;

    @QueryControl({
        label: '属性',
        type: QueryControlType.SELECT,
        options: ParkingTypeList,
        span: 8,
        required: true
    })
    parkingType: string = 'freeCount';

    @QueryControl({
        label: ' ',
        type: QueryControlType.RADIO_GROUP,
        options: [{ name: '合并', value: true },
            { name: '不合并', value: false }],
        span: 8
    })
    sum: boolean= false;

    toService() {
        return {
            areas: this.areaId,
            startTime: moment().startOf('day').toDate().getTime(),
            endTime: moment().add(1, 'day').startOf('day').toDate().getTime(),
            sum: this.sum
        };
    }
}

export async function ParkingAreaRealtimeService(model: ParkingAreaRealtimeQueryModel) {
    const url = `${REPORT_URL_PATH}/parking/trend`;
    const params = model.toService();
    const res = await post(url, params);
    const parkingType = _.find(ParkingTypeList, item => item.value === model.parkingType);
    // { code: 'rate', name: '占有率' }
    const chartOptions = {
        data: timeTransformData(res, [{ code: 'timestamp', name: '小时' }, { code: parkingType.value, name: parkingType.name }], 'MM-DD'),
        settings: {
            type: 'histogram',
            yAxisType: parkingType.value === 'rate' ? ['percent'] : ['KMB']
        }
    };
    return assignDefaultExtend(chartOptions);
}
