import moment, { Moment } from 'moment';
import { GraphGroupName } from '@/model/enum';
import { QueryControl, QueryControlType } from '@/model/query-control';
import { post, download } from '@/service/request';
import ReportService, { REPORT_URL_PATH, assignDefaultExtend, timeTransformData } from '@/service/report';
import { QueryModel } from '@/model/query-model';
export const CustomerFlowTypeList = [
    { value: 'inCount', name: '进客流' },
    { value: 'outCount', name: '出客流' },
    { value: 'strandedCount', name: '留存客流' }
];

export class BusinessAreaRealtimeCustomerQueryModel extends QueryModel {
    @QueryControl({
        label: '区域选择',
        type: QueryControlType.SELECT,
        mode: 'multiple',
        optionsPromise: ReportService.getLocationList,
        optionsPromiseParam: GraphGroupName.CUSTOMER_FLOW_AREA,
        index: 10,
        span: 8,
        defaultValue: 'ALL',
        required: true
    })
    areaId: Array<string> = undefined;

    @QueryControl({
        label: '日期选择',
        type: QueryControlType.SELECT_DATE,
        showTime: false,
        required: true,
        isRange: false
    })
    date: Moment = moment();

    @QueryControl({
        label: '属性',
        type: QueryControlType.SELECT,
        options: CustomerFlowTypeList,
        span: 8,
        required: true
    })
    customerFlowType: string = 'inCount';

    toService() {
        return {
            groups: this.areaId,
            startTime: this.date.startOf('day').toDate().getTime(),
            endTime: this.date.clone().add(1, 'day').startOf('day').toDate().getTime(),
            sum: false
        };
    }
}

export async function BusinessAreaRealtimeCustomerService(model: BusinessAreaRealtimeCustomerQueryModel) {
    const url = `${REPORT_URL_PATH}/customerFlow/trend/daily`;
    const params = model.toService();
    const res = await post(url, params);
    const customerFlowType = _.find(CustomerFlowTypeList, item => item.value === model.customerFlowType);
    const rows = _.map(res, item => {
        return {
            name: item.name,
            value: _.sumBy(item.flowData, fd => fd[model.customerFlowType])
        };
    });
    const total = _.sumBy(rows, item => item.value);
    _.forEach(rows, (item: any) => {
        item.ratio = ((item.value / total * 100) as any).toFixed(2) + '%';
    });
    const chartOptions = {
        data: {
            columns: ['name', 'value'],
            rows
        },
        settings: {
            type: 'ring',
            radius: ['25%', '65%'],
            offsetY: '50%'
        },
        extend: {
            series: {
                label: {
                    show: true,
                    normal: {
                        formatter: '{d|{d}%}\n{b|{b}} {c|{c}}', // 图形外文字上下显示
                        padding: [0, -50], // 文字和图的边距
                        rich: {
                            b: { // name 文字样式
                                fontSize: 12,
                                lineHeight: 20
                            },
                            c: { // value 文字样式
                                fontSize: 12,
                                lineHeight: 20
                            },
                            d: {
                                lineHeight: 25,
                                verticalAlign: 'center',
                                align: 'left'

                            }
                        }
                    }
                },
                labelLine: {
                    length: 60,
                    length2: 60
                }
            }
        },
        tableColumns: [
            {
                title: '',
                dataIndex: 'name'
            },
            {
                title: customerFlowType.name,
                dataIndex: 'value'
            },
            {
                title: '占比',
                dataIndex: 'ratio'
            }
        ]
    };
    return assignDefaultExtend(chartOptions);
}

export async function BusinessAreaRealtimeCustomerExportService(model: BusinessAreaRealtimeCustomerQueryModel) {
    const url = `${REPORT_URL_PATH}/customerFlow/analyse/hourly/export`;
    const params = model.toService();
    const res = await download(url, params, 'post');
    return res;
}
