import { Moment } from 'moment';
import { GraphGroupName } from '@/model/enum';
import { QueryControl, QueryControlType } from '@/model/query-control';
import { post } from '@/service/request';
import ReportService, { REPORT_URL_PATH, assignDefaultExtend, timeTransformData } from '@/service/report';
import { QueryModel } from '@/model/query-model';

export class HvacRealtimeDeviceStatusQueryModel extends QueryModel {
    @QueryControl({
        label: '专业选择',
        type: QueryControlType.SELECT,
        mode: 'multiple',
        optionsPromise: ReportService.getLocationList,
        optionsPromiseParam: GraphGroupName.HVAC_PROFESSION,
        index: 1,
        span: 8,
        defaultValue: 'ALL',
        required: true
    })
    groupIds: string = undefined;

    @QueryControl({
        type: QueryControlType.RADIO_GROUP,
        options: [{ name: '合并', value: true },
            { name: '不合并', value: false }],
        span: 8
    })
    sum: boolean= false;

    toService() {
        return {
            groupIds: this.groupIds,
            sum: this.sum
        };
    }
}

export async function HvacRealtimeDeviceStatusService(model: HvacRealtimeDeviceStatusQueryModel) {
    const url = `${REPORT_URL_PATH}/equipment/hvac/runningCount`;
    const params = model.toService();
    const rows:any = await post(url, params);
    // const rows: any = await getReportRealtimeDeviceProfessionJsonData();
    const chartOptions = {
        data: {
            columns: ['name', 'stopCount', 'runningCount'],
            rows
        },
        settings: {
            type: 'histogram',
            labelMap: {
                stopCount: '停机数',
                runningCount: '运行数'
            }
        },
        extend: {
            yAxis: {
                name: '单位：台',
                position: 'left',
                axisLine: {
                    show: true
                },
                splitLine: {
                    lineStyle: {
                        type: 'dashed'
                    }
                }
            }
        }
    };
    return assignDefaultExtend(chartOptions);
}
