import { Moment } from 'moment';
import { GraphGroupName } from '@/model/enum';
import { QueryControl, QueryControlType } from '@/model/query-control';
import { post } from '@/service/request';
import ReportService, { REPORT_URL_PATH, assignDefaultExtend, timeTransformData } from '@/service/report';
import { QueryModel } from '@/model/query-model';
import { CustomerFlowTypeList } from './business-area-realtime-customer';

export class BusinessAreaHourCustomerQueryModel extends QueryModel {
    @QueryControl({
        label: '区域选择',
        type: QueryControlType.SELECT,
        mode: 'multiple',
        optionsPromise: ReportService.getLocationList,
        optionsPromiseParam: GraphGroupName.CUSTOMER_FLOW_AREA,
        index: 10,
        span: 8,
        defaultValue: 'ALL',
        required: true
    })
    areaId: Array<string> = undefined;

    @QueryControl({
        label: '日期范围',
        type: QueryControlType.SELECT_DATE,
        showTime: true,
        span: 10
    })
    dateRange: Array<Moment> = JTL.CONSTANT.TODAY();

    @QueryControl({
        label: '属性',
        type: QueryControlType.SELECT,
        options: CustomerFlowTypeList,
        span: 8,
        required: true
    })
    customerFlowType: string = 'inCount';

    @QueryControl({
        label: ' ',
        type: QueryControlType.RADIO_GROUP,
        options: [{ name: '合并', value: true },
            { name: '不合并', value: false }],
        span: 8
    })
    sum: boolean= false;
    toService() {
        if (this.dateRange && this.dateRange.length === 2) {
            return {
                groups: this.areaId,
                startTime: this.dateRange[0].toDate().getTime(),
                endTime: this.dateRange[1].toDate().getTime(),
                sum: this.sum
            };
        }
    }
}

export async function BusinessHourCustomerService(model: BusinessAreaHourCustomerQueryModel) {
    const url = `${REPORT_URL_PATH}/customerFlow/trend/hourly`;
    const params = model.toService();
    const res = await post(url, params);
    const customerFlowType = _.find(CustomerFlowTypeList, item => item.value === model.customerFlowType);
    const chartOptions = {
        data: timeTransformData(res, [{ code: 'timestamp', name: '时间' }, { code: customerFlowType.value, name: customerFlowType.name }], 'MM-DD HH:mm'),
        settings: {
            type: 'line'
        }
    };
    return assignDefaultExtend(chartOptions);
}
