

























































import { Component, Ref, Watch, Mixins } from 'vue-property-decorator';
import TableComponent from '@/mixins/table-component';
import { ReportChartBaseComponent } from '@/mixins/report-chart-base';
import { JTLTableQueryComponent } from '@/components';
import { BusinessDayHourCustomerQueryModel, BusinessDayHourCustomerService, BusinessDayHourCustomerExportService } from './model/business-day-hour-customer';
import { BusinessAreaDayCustomerQueryModel, BusinessDayCustomerService } from './model/business-area-day-customer';
import { BusinessAreaHourCustomerQueryModel, BusinessHourCustomerService } from './model/business-area-hour-customer';
import { BusinessFloorDayCustomerQueryModel } from './model/business-floor-day-customer';
import { BusinessFloorHourCustomerQueryModel } from './model/business-floor-hour-customer';
import { BusinessAreaRealtimeCustomerQueryModel, BusinessAreaRealtimeCustomerService, BusinessAreaRealtimeCustomerExportService } from './model/business-area-realtime-customer';
import { BusinessFloorRealtimeCustomerQueryModel } from './model/business-floor-realtime-customer';
import { HvacRealtimeBreakdownTypeQueryModel, HvacRealtimeBreakdownTypeService } from './model/hvac-realtime-breakdown-type';
import { HvacRunningTimeQueryModel, HvacRunningTimeService } from './model/hvac-running-time';
import { HvacRealtimeDeviceStatusQueryModel, HvacRealtimeDeviceStatusService } from './model/hvac-realtime-device-status';
import { DhcHourCopQueryModel, DhcHourCopService } from './model/dhc-hour-cop';
import { DhcRealtimeBreakdownQueryModel, DhcRealtimeBreakdownService } from './model/dhc-realtime-breakdowm-type';
import { DhcDeviceRunningDataQueryModel, DhcDeviceRunningDataService } from './model/dhc-device-running-data';
import { DhcRunningTimeQueryModel, DhcRunningTimeService } from './model/dhc-running-time';
import { ParkingAreaHourTrendQueryModel, ParkingAreaHourTrendService } from './model/parking-area-hour-trend';
import { ParkingFloorHourTrendQueryModel, ParkingFloorHourTrendService } from './model/parking-floor-hour-trend';
import { ParkingAreaRealtimeQueryModel, ParkingAreaRealtimeService } from './model/parking-area-realtime';
import { ParkingFloorRealtimeQueryModel, ParkingFloorRealtimeService } from './model/parking-floor-realtime';
import { CameraAreaDeviceQueryModel, CameraAreaDeviceService } from './model/camera-area-device';
import { CameraFloorDeviceQueryModel, CameraFloorDeviceService } from './model/camera-floor-device';

@Component
export default class ReportChartComponent extends Mixins(ReportChartBaseComponent, TableComponent) {
    /**
     * 图表的数据和配置
     */
    chartOptions: any = null;

    /**
     * 表格的数据和配置
     */
    tableOptions: any = null;

    /**
     * 图标的查询方法
     */
    chartService: Function = null;

    /**
     * 表格的查询数据服务
     */
    tableService: Function = null;

    /**
     * 表格的导出数据服务
     */
    exportExcelService: Function = null;

    /**
     * 图标的搜索模型
     */
    queryModel: any = null;

    /**
     * 导出Excel的搜索模型
     */
    exportQueryModel: any = null;

    /**
     * 图表正在加载标识
     */
    chartLoading: boolean = false;

    /**
     * 图标的高度
     */
    chartHeight: number = 400;

    /**
     * 图标宽度
     */
    chartWidth: number = 600;

    tableExpandedRowKeys: Array<string> = [];

    /**
     * 图表查询组件
     */
    @Ref(JTL.CONSTANT.DEFAULT_TABLE_QUERY_NAME)
    queryComponent: JTLTableQueryComponent;

    mounted() {
        setTimeout(() => {
            // 图标的宽度= 屏幕的宽度- leftMenuWidth - routeViewPadding - leftSubMenuWidth - leftSubMenuPaddingRight - cardChartPadding
            this.chartWidth = document.getElementById('report_index_component').scrollWidth - 288;
            // 图标的高度= 屏幕的高度- header - breadcrumb - breadcrumbBottom - cardQuery - cardQueryBottom - cardChartHeader - cardChartPadding - bottom
            const cHeight = document.body.clientHeight - 355 - 100;
            if (cHeight > 400) {
                this.chartHeight = cHeight;
            }
            this.initChart();
        }, 100);
    }

    @Watch('$route')
    routeChange() {
        this.resetChart();
        this.initChart();
    }

    /**
     * 获取展示类型
     * 图表/表格
     */
    get IsChart(): boolean {
        return _.get(this.chartOptions, 'settings.type') !== 'table';
    }

    /**
     * 获取图表表格数据
     */
    get ChartTableData() {
        return _.get(this.chartOptions, 'data.rows');
    }

    /**
     * 获取图表表格列
     */
    get ChartTableColumns() {
        return _.get(this.chartOptions, 'data.columns');
    }

    /**
     * 获取表格数据
     */
    get TableData() {
        return _.get(this.chartOptions, 'tableRows') || this.ChartTableData;
    }

    /**
     * 获取表格列
     */
    get TableColumns() {
        return _.get(this.chartOptions, 'tableColumns');
    }

    // get TableExpandedRowKeys() {
    //     return _.get(this.chartOptions, 'tableExpandedRowKeys');
    // }

    /**
     * 获取图表数据
     */
    get ChartData() {
        return _.get(this.chartOptions, 'data');
    }

    /**
     * 获取图表配置
     */
    get ChartSettings() {
        return _.get(this.chartOptions, 'settings');
    }

    /**
     * 获取图表配置
     */
    get ChartExtend() {
        return _.get(this.chartOptions, 'extend');
    }

    /**
     * 获取分页的配置
     */
    get ReportPagination() {
        return {
            pageSize: this.pageSize,
            total: this.total,
            current: this.page,
            showSizeChanger: false,
            showQuickJumper: false
        };
    }

    /**
     * 图标搜索按钮的点击事件
     */
    chartSearchClick() {
        if (!this.queryComponent.validate()) {
            return;
        }
        return Promise.all([this.getChartData(), this.getTableData()]);
    }

    /**
     * 获取图表数据
     */
    getChartData(): Promise<any> {
        if (!this.chartService) {
            return new Promise((res, rej) => {
                res(null);
            });
        }
        this.tableExpandedRowKeys = [];
        this.chartLoading = true;
        return this.chartService(this.queryModel).then(res => {
            this.chartOptions = res;
            this.tableExpandedRowKeys = _.get(this.chartOptions, 'tableExpandedRowKeys') || [];
            // this.$forceUpdate();
            setTimeout(() => {
                this.expandedRowsChange(_.get(this.chartOptions, 'tableExpandedRowKeys') || []);
            }, 200);
            this.exportQueryModel = _.cloneDeep(this.queryModel);
        }).finally(() => {
            // 延迟100ms显示echarts图表
            // 为了显示echarts初始化时的动画效果
            setTimeout(() => { this.chartLoading = false; }, 100);
        });
    }

    /**
     * 获取表格数据
     */
    getTableData(): Promise<any> {
        if (!this.tableService) {
            return new Promise((res, rej) => {
                res(null);
            });
        }
        return this.tableService(this.queryModel).then(res => {
            this.tableOptions = res;
        });
    }

    /**
     * 导出Excel点击事件
     */
    exportExcelClick() {
        if (!this.exportExcelService) {
            this.showDeveloping();
            return;
        }
        return this.exportExcelService(this.exportQueryModel).then(res => {
            this.showMessageSuccess('导出Excel成功');
        });
    }

    /**
     * 切换显示数量
     */
    showNumber(value: any) {
        this.chartOptions.extend.series.label.show = value.target.checked;
        (this.$refs.jtlChart as any).dataHandler();
    }

    /**
     * 初始化图表的数据和配置
     */
    private initChart() {
        if (!this.queryComponent) {
            return;
        }

        switch (this.ChartCode) {
            case '/report/business/day-hour-customer':// 日客流分时统计
                this.queryModel = new BusinessDayHourCustomerQueryModel();
                this.chartService = BusinessDayHourCustomerService;
                this.exportExcelService = BusinessDayHourCustomerExportService;
                break;
            case '/report/business/area-day-customer':// 区域日客流统计
                this.queryModel = new BusinessAreaDayCustomerQueryModel();
                this.chartService = BusinessDayCustomerService;
                this.exportExcelService = null;
                break;
            case '/report/business/area-hour-customer':// 区域分时客流统计
                this.queryModel = new BusinessAreaHourCustomerQueryModel();
                this.chartService = BusinessHourCustomerService;
                this.exportExcelService = null;
                break;
            case '/report/business/floor-day-customer': // 楼层日客流统计
                this.queryModel = new BusinessFloorDayCustomerQueryModel();
                this.chartService = BusinessDayCustomerService;
                this.exportExcelService = null;
                break;
            case '/report/business/floor-hour-customer': // 楼层分时客流统计
                this.queryModel = new BusinessFloorHourCustomerQueryModel();
                this.chartService = BusinessHourCustomerService;
                this.exportExcelService = null;
                break;
            case '/report/business/area-realtime-customer': // 实时客流区域分布
                this.queryModel = new BusinessAreaRealtimeCustomerQueryModel();
                this.chartService = BusinessAreaRealtimeCustomerService;
                this.exportExcelService = BusinessAreaRealtimeCustomerExportService;
                break;
            case '/report/business/floor-realtime-customer': // 实时客流楼层分布
                this.queryModel = new BusinessFloorRealtimeCustomerQueryModel();
                this.chartService = BusinessAreaRealtimeCustomerService;
                this.exportExcelService = BusinessAreaRealtimeCustomerExportService;
                break;
            case '/report/parking/area-hour-trend':// 停车区域分时趋势
                this.queryModel = new ParkingAreaHourTrendQueryModel();
                this.chartService = ParkingAreaHourTrendService;
                this.exportExcelService = null;
                break;
            case '/report/parking/floor-hour-trend': // 停车楼层分时趋势
                this.queryModel = new ParkingFloorHourTrendQueryModel();
                this.chartService = ParkingFloorHourTrendService;
                this.exportExcelService = null;
                break;
            case '/report/parking/area-realtime':// 停车实时区域统计
                this.queryModel = new ParkingAreaRealtimeQueryModel();
                this.chartService = ParkingAreaRealtimeService;
                this.exportExcelService = null;
                break;
            case '/report/parking/floor-realtime':// 停车实时楼层统计
                this.queryModel = new ParkingFloorRealtimeQueryModel();
                this.chartService = ParkingFloorRealtimeService;
                this.exportExcelService = null;
                break;
            case '/report/camera/area-device':// 视频监控设备数量区域统计
                this.queryModel = new CameraAreaDeviceQueryModel();
                this.chartService = CameraAreaDeviceService;
                this.exportExcelService = null;
                break;
            case '/report/camera/floor-device':// 视频监控设备数量楼层统计
                this.queryModel = new CameraFloorDeviceQueryModel();
                this.chartService = CameraFloorDeviceService;
                this.exportExcelService = null;
                break;
            case '/report/dhc/hour-cop': // dhc-分时COP统计
                this.queryModel = new DhcHourCopQueryModel();
                this.chartService = DhcHourCopService;
                this.exportExcelService = null;
                break;
            case '/report/dhc/realtime-breakdown-type':// dhc-实时故障分类
                this.queryModel = new DhcRealtimeBreakdownQueryModel();
                this.chartService = DhcRealtimeBreakdownService;
                this.exportExcelService = null;
                break;
            case '/report/dhc/device-running-data':// dhc-设备运行状态
                this.queryModel = new DhcDeviceRunningDataQueryModel();
                this.chartService = DhcDeviceRunningDataService;
                this.exportExcelService = null;
                break;
            case '/report/dhc/running-time':// dhc-运行时长统计
                this.queryModel = new DhcRunningTimeQueryModel();
                this.chartService = DhcRunningTimeService;
                this.exportExcelService = null;
                break;
            case '/report/hvac/realtime-breakdowm-type':// 暖通-实时故障分类
                this.queryModel = new HvacRealtimeBreakdownTypeQueryModel();
                this.chartService = HvacRealtimeBreakdownTypeService;
                this.exportExcelService = null;
                break;
            case '/report/hvac/running-time':// 暖通-运行时长统计
                this.queryModel = new HvacRunningTimeQueryModel();
                this.chartService = HvacRunningTimeService;
                this.exportExcelService = null;
                break;
            case '/report/hvac/realtime-device-status':// 暖通-实时设备状态统计
                this.queryModel = new HvacRealtimeDeviceStatusQueryModel();
                this.chartService = HvacRealtimeDeviceStatusService;
                this.exportExcelService = null;
                break;
        }

        this.queryComponent.initQuery(this.queryModel);
    }

    /**
     * 重置图表数据和配置
     */
    private resetChart() {
        this.chartOptions = null;
        this.tableOptions = null;
        this.chartService = null;
        this.tableService = null;
        this.queryModel = null;
        this.exportQueryModel = null;
    }

    expandedRowsChange(expandedRows) {
        this.tableExpandedRowKeys = expandedRows;
    }
}

