import moment, { Moment } from 'moment';
import { GraphGroupName } from '@/model/enum';
import { QueryControl, QueryControlType } from '@/model/query-control';
import { post, download } from '@/service/request';
import ReportService, { REPORT_URL_PATH, assignDefaultExtend, timeTransformData } from '@/service/report';
import { QueryModel } from '@/model/query-model';
import { dateFormat, percentFormat, kilo } from '@/filter';
import { generateUUID } from '@/utils/base_util';

export class BusinessDayHourCustomerQueryModel extends QueryModel {
    @QueryControl({
        label: '区域选择',
        type: QueryControlType.SELECT,
        mode: 'multiple',
        optionsPromise: ReportService.getLocationList,
        optionsPromiseParam: GraphGroupName.CUSTOMER_FLOW_AREA,
        index: 10,
        span: 8,
        defaultValue: 'ALL',
        required: true
    })
    areaIds: Array<string> = undefined;

    @QueryControl({
        label: '日期选择',
        type: QueryControlType.SELECT_DATE,
        showTime: false,
        required: true,
        isRange: false
    })
    date: Moment = moment();

    toService() {
        if (this.date) {
            return {
                groups: this.areaIds,
                startTime: this.date.startOf('day').toDate().getTime(),
                endTime: this.date.clone().add(1, 'day').startOf('day').toDate().getTime()
            };
        }
    }
}

export async function BusinessDayHourCustomerService(model: BusinessDayHourCustomerQueryModel) {
    const url = `${REPORT_URL_PATH}/customerFlow/analyse/hourly`;
    const params = model.toService();
    const res = await post(url, params);
    const resData = _.map(res, item => {
        return {
            name: item.name,
            flowData: _.map(item.flowData, fItem => {
                return {
                    timestamp: fItem.timestamp,
                    count: fItem.inCount + fItem.outCount,
                    checkChainIn: fItem.checkChainIn,
                    checkChainOut: fItem.checkChainOut,
                    checkYoyIn: fItem.checkYoyIn,
                    checkYoyOut: fItem.checkYoyOut
                };
            })
        };
    });
    const tableExpandedRowKeys = [];
    const tableRows = _.map(res, item => {
        item.id = generateUUID();
        tableExpandedRowKeys.push(item.id);
        item.timestamp = item.name;
        item.children = _.map(item.flowData || [], item => {
            item.id = generateUUID();
            return item;
        });
        item.children.push({
            id: generateUUID(),
            timestamp: '平均值',
            inCount: item.inCountAvg,
            outCount: item.outCountAvg,
            strandedCount: item.strandedCountAvg,
            yearOnYearRatioOfInCount: item.yearOnYearRatioOfInCountAvg,
            chainRatioOfInCount: item.chainRatioOfInCountAvg,
            yearOnYearRatioOfOutCount: item.yearOnYearRatioOfOutCountAvg,
            chainRatioOfOutCount: item.chainRatioOfOutCountAvg,
            isCheckYoyOut: item.isCheckYoyOut
        }, {
            id: generateUUID(),
            timestamp: '总计',
            inCount: item.inCountTotal,
            outCount: item.outCountTotal,
            strandedCount: item.strandedCountTotal,
            yearOnYearRatioOfInCount: item.yearOnYearRatioOfInCountTotal,
            chainRatioOfInCount: item.chainRatioOfInCountTotal,
            yearOnYearRatioOfOutCount: item.yearOnYearRatioOfOutCountTotal,
            chainRatioOfOutCount: item.chainRatioOfOutCountTotal,
            isCheckYoyOut: item.isCheckYoyOut
        }, {
            id: generateUUID(),
            timestamp: '最大值',
            inCount: item.inCountMax,
            outCount: item.outCountMax,
            strandedCount: item.strandedCountMax,
            yearOnYearRatioOfInCount: item.yearOnYearRatioOfInCountMax,
            chainRatioOfInCount: item.chainRatioOfInCountMax,
            yearOnYearRatioOfOutCount: item.yearOnYearRatioOfOutCountMax,
            chainRatioOfOutCount: item.chainRatioOfOutCountMax,
            isCheckYoyOut: item.isCheckYoyOut
        }, {
            id: generateUUID(),
            timestamp: '最小值',
            inCount: item.inCountMin,
            outCount: item.outCountMin,
            strandedCount: item.strandedCountMin,
            yearOnYearRatioOfInCount: item.yearOnYearRatioOfInCountMin,
            chainRatioOfInCount: item.chainRatioOfInCountMin,
            yearOnYearRatioOfOutCount: item.yearOnYearRatioOfOutCountMin,
            chainRatioOfOutCount: item.chainRatioOfOutCountMin,
            isCheckYoyOut: item.isCheckYoyOut
        });
        return item;
    });
    // const tableRows = _.get(res, '[0].flowData') || [];
    // tableRows.push({
    //     timestamp: '平均值',
    //     inCount: _.get(res, '[0].inCountAvg'),
    //     outCount: _.get(res, '[0].outCountAvg'),
    //     strandedCount: _.get(res, '[0].strandedCountAvg'),
    //     yearOnYearRatioOfInCount: _.get(res, '[0].yearOnYearRatioOfInCountAvg'),
    //     chainRatioOfInCount: _.get(res, '[0].chainRatioOfInCountAvg'),
    //     yearOnYearRatioOfOutCount: _.get(res, '[0].yearOnYearRatioOfOutCountAvg'),
    //     chainRatioOfOutCount: _.get(res, '[0].chainRatioOfOutCountAvg'),
    //     isCheckYoyOut: _.get(res, '[0].isCheckYoyOut')
    // }, {
    //     timestamp: '总计',
    //     inCount: _.get(res, '[0].inCountTotal'),
    //     outCount: _.get(res, '[0].outCountTotal'),
    //     strandedCount: _.get(res, '[0].strandedCountTotal'),
    //     yearOnYearRatioOfInCount: _.get(res, '[0].yearOnYearRatioOfInCountTotal'),
    //     chainRatioOfInCount: _.get(res, '[0].chainRatioOfInCountTotal'),
    //     yearOnYearRatioOfOutCount: _.get(res, '[0].yearOnYearRatioOfOutCountTotal'),
    //     chainRatioOfOutCount: _.get(res, '[0].chainRatioOfOutCountTotal'),
    //     isCheckYoyOut: _.get(res, '[0].isCheckYoyOut')
    // }, {
    //     timestamp: '最大值',
    //     inCount: _.get(res, '[0].inCountMax'),
    //     outCount: _.get(res, '[0].outCountMax'),
    //     strandedCount: _.get(res, '[0].strandedCountMax'),
    //     yearOnYearRatioOfInCount: _.get(res, '[0].yearOnYearRatioOfInCountMax'),
    //     chainRatioOfInCount: _.get(res, '[0].chainRatioOfInCountMax'),
    //     yearOnYearRatioOfOutCount: _.get(res, '[0].yearOnYearRatioOfOutCountMax'),
    //     chainRatioOfOutCount: _.get(res, '[0].chainRatioOfOutCountMax'),
    //     isCheckYoyOut: _.get(res, '[0].isCheckYoyOut')
    // }, {
    //     timestamp: '最小值',
    //     inCount: _.get(res, '[0].inCountMin'),
    //     outCount: _.get(res, '[0].outCountMin'),
    //     strandedCount: _.get(res, '[0].strandedCountMin'),
    //     yearOnYearRatioOfInCount: _.get(res, '[0].yearOnYearRatioOfInCountMin'),
    //     chainRatioOfInCount: _.get(res, '[0].chainRatioOfInCountMin'),
    //     yearOnYearRatioOfOutCount: _.get(res, '[0].yearOnYearRatioOfOutCountMin'),
    //     chainRatioOfOutCount: _.get(res, '[0].chainRatioOfOutCountMin'),
    //     isCheckYoyOut: _.get(res, '[0].isCheckYoyOut')
    // });
    const chartOptions = {
        data: timeTransformData(resData, [{ code: 'timestamp', name: '时间' }, { code: 'count', name: '进客流 + 出客流' }], 'MM-DD HH:mm'),
        settings: {
            type: 'line'
        },
        tableColumns: [
            {
                title: '',
                dataIndex: 'timestamp',
                customRender: (text, record, index) => {
                    if (text) {
                        const dateString = dateFormat(text, 'MM-DD HH:mm');
                        return dateString === 'Invalid date' ? text : dateString;
                    }
                    return '-';
                }
            },
            {
                title: '进客流',
                dataIndex: 'inCount',
                customRender: (text, record, index) => {
                    if (record.children) {
                        return '';
                    }
                    return kilo(text);
                }
            },
            {
                title: '出客流',
                dataIndex: 'outCount',
                customRender: (text, record, index) => {
                    if (record.children) {
                        return '';
                    }
                    return kilo(text);
                }
            },
            {
                title: '留存客流',
                dataIndex: 'strandedCount',
                customRender: (text, record, index) => {
                    if (record.children) {
                        return '';
                    }
                    return kilo(text);
                }
            },
            {
                title: '同比（进客流）',
                dataIndex: 'yearOnYearRatioOfInCount',
                customRender: (text, record, index) => {
                    if (record.children) {
                        return '';
                    }
                    if (text && record.checkYoyIn) {
                        return percentFormat(text);
                    }
                    return '-';
                }
            },
            {
                title: '环比（进客流）',
                dataIndex: 'chainRatioOfInCount',
                customRender: (text, record, index) => {
                    if (record.children) {
                        return '';
                    }
                    if (text && record.checkChainOut) {
                        return percentFormat(text);
                    }
                    return '-';
                }
            },
            {
                title: '同比（出客流）',
                dataIndex: 'yearOnYearRatioOfOutCount',
                customRender: (text, record, index) => {
                    if (record.children) {
                        return '';
                    }
                    if (text && record.isCheckYoyOut) {
                        return percentFormat(text);
                    }
                    return '-';
                }
            },
            {
                title: '环比（出客流）',
                dataIndex: 'chainRatioOfOutCount',
                customRender: (text, record, index) => {
                    if (record.children) {
                        return '';
                    }
                    if (text && record.isCheckYoyOut) {
                        return percentFormat(text);
                    }
                    return '-';
                }
            }
        ],
        tableRows,
        tableExpandedRowKeys
    };
    return assignDefaultExtend(chartOptions);
}

export async function BusinessDayHourCustomerExportService(model: BusinessDayHourCustomerQueryModel) {
    const url = `${REPORT_URL_PATH}/customerFlow/analyse/hourly/export`;
    const params = model.toService();
    const res = await download(url, params, 'post');
    return res;
}
