import { Moment } from 'moment';
import { GraphGroupName } from '@/model/enum';
import { QueryControl, QueryControlType } from '@/model/query-control';
import { post } from '@/service/request';
import ReportService, { REPORT_URL_PATH, assignDefaultExtend, timeTransformData } from '@/service/report';
import { QueryModel } from '@/model/query-model';

export class HvacRunningTimeQueryModel extends QueryModel {
    @QueryControl({
        label: '专业选择',
        type: QueryControlType.SELECT,
        selectChildren: false,
        optionsPromise: ReportService.getLocationList,
        optionsPromiseParam: GraphGroupName.HVAC_PROFESSION,
        index: 1,
        span: 8,
        defaultValue: '[0].value',
        required: true
    })
    groupId: string = undefined;

    @QueryControl({
        label: '日期范围',
        type: QueryControlType.SELECT_DATE,
        showTime: false,
        span: 10,
        required: true
    })
    dateRange: Array<Moment> = JTL.CONSTANT.LAST_7_DAY();

    toService() {
        return {
            startTime: this.dateRange[0].startOf('day').toDate().getTime(),
            endTime: this.dateRange[1].clone().add(1, 'day').startOf('day').toDate().getTime(),
            groupId: this.groupId
        };
    }
}

export async function HvacRunningTimeService(model: HvacRunningTimeQueryModel) {
    const url = `${REPORT_URL_PATH}/equipment/hvac/runningTime`;
    const params = model.toService();
    const rows:any = await post(url, params);
    // const rows: any = await getReportRunningTimeDHCJsonData();
    const chartOptions = {
        data: {
            columns: ['name', 'maxTime', 'averageTime', 'deviationTime'],
            rows
        },
        settings: {
            type: 'histogram',
            showLine: ['deviationTime'],
            labelMap: {
                maxTime: '最高运行时长',
                averageTime: '平均时长',
                deviationTime: '偏差值'
            }
        },
        extend: {
            yAxis: {
                name: '单位：时长',
                position: 'left',
                axisLine: {
                    show: true
                },
                splitLine: {
                    lineStyle: {
                        type: 'dashed'
                    }
                }
            }
        }
    };
    return assignDefaultExtend(chartOptions);
}
