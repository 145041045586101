import { Moment } from 'moment';
import { GraphGroupName } from '@/model/enum';
import { QueryControl, QueryControlType } from '@/model/query-control';
import { post } from '@/service/request';
import ReportService, { REPORT_URL_PATH, assignDefaultExtend, timeTransformData } from '@/service/report';
import { QueryModel } from '@/model/query-model';
import DhcDeviceRunningTimeData from '@/json/report-mock-dhc-device-running-time.json';

function getDhcDeviceRunningJsonData() {
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            resolve(DhcDeviceRunningTimeData.items);
        }, 2000);
    });
}

export class DhcDeviceRunningDataQueryModel extends QueryModel {
    @QueryControl({
        label: '专业选择',
        type: QueryControlType.SELECT,
        selectChildren: false,
        optionsPromise: ReportService.getLocationList,
        optionsPromiseParam: GraphGroupName.DHC_PROFESSION,
        index: 1,
        span: 8,
        defaultValue: '[0].value',
        require: true
    })
    groupId: string = undefined;

    @QueryControl({
        label: '日期范围',
        type: QueryControlType.SELECT_DATE,
        showTime: false,
        span: 10,
        require: true
    })
    dateRange: Array<Moment> = JTL.CONSTANT.LAST_7_DAY();

    toService() {
        return {
            startTime: this.dateRange[0].startOf('day').toDate().getTime(),
            endTime: this.dateRange[1].clone().add(1, 'day').startOf('day').toDate().getTime(),
            groupId: this.groupId
        };
    }
}

export async function DhcDeviceRunningDataService(model: DhcDeviceRunningDataQueryModel) {
    const url = `${REPORT_URL_PATH}/equipment/dhc/energyEfficiencyAnalyse`;
    const params = model.toService();
    const rows:any = await post(url, params);
    // const rows:any = await getDhcDeviceRunningJsonData();
    const chartOptions = {
        data: {
            columns: [
                {
                    title: '设备名',
                    dataIndex: 'name'
                },
                {
                    title: '用电量',
                    dataIndex: 'power'
                },
                {
                    title: '负荷',
                    dataIndex: 'load'
                },
                {
                    title: '平均能耗',
                    dataIndex: 'averageEnergyConsume'
                }
            ],
            rows
        },
        settings: {
            type: 'table'
        }
    };
    return assignDefaultExtend(chartOptions);
}
